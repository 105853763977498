import { ArrowRight } from "@phosphor-icons/react"
import { Box } from "@tokenterminal/ui/Box"
import { LinkButtonV4 } from "@tokenterminal/ui/Button/LinkButton.v4"
import { Card } from "@tokenterminal/ui/Card/Card"
import { Icon } from "@tokenterminal/ui/Icon/Icon"
import { Row } from "@tokenterminal/ui/Row"
import { Stack } from "@tokenterminal/ui/Stack"
import { Text } from "@tokenterminal/ui/Text"
import Image from "next/image"
import { type ReactNode } from "react"
import {
  mainFeaturesGridCss,
  imageContainerCss,
  imageGradientCss,
  mainFeaturesStackCss,
  featureCalloutContainerStackCss,
  featureCalloutContainerRowCss,
  featureCalloutContentStackCss,
  featureCalloutCTACss,
} from "./FeatureCallouts.css"

export type Feature = {
  id: string
  title: ReactNode
  description: ReactNode
  ctaText?: string
  ctaHref?: string
  image: string
  badgeText?: string
}

const FeatureCallouts = ({ features }: { features: Array<Feature> }) => {
  return (
    <Row justifyContent="center">
      <Box
        className={
          features.length === 3 ? mainFeaturesStackCss : mainFeaturesGridCss
        }
      >
        {features.map((feature) => (
          <Box
            key={feature.id}
            className={
              features.length === 3
                ? featureCalloutContainerRowCss
                : featureCalloutContainerStackCss
            }
          >
            <Stack className={featureCalloutContentStackCss} gap="2x">
              {feature.badgeText && (
                <Box
                  style={{
                    border: "1px solid #383838",
                    borderRadius: "16px",
                    fontSize: "12px",
                    marginBottom: "5px",
                    padding: "3px 6px 3px 7px",
                    color: "#bbb",
                    width: "fit-content",
                  }}
                >
                  {feature.badgeText}
                </Box>
              )}
              <Text
                size="20"
                fontWeight="500"
                style={{ letterSpacing: "-0.5px" }}
              >
                {feature.title}
              </Text>
              <Text style={{ color: "#888" }}>{feature.description}</Text>
              {feature.ctaText && feature.ctaHref && (
                <LinkButtonV4
                  variant="ghost"
                  href={feature.ctaHref}
                  className={featureCalloutCTACss}
                >
                  {feature.ctaText}
                  <Icon as={ArrowRight} />
                </LinkButtonV4>
              )}
            </Stack>
            <Card className={imageContainerCss}>
              <Image
                alt={feature.image}
                src={feature.image}
                width={520}
                height={500}
                style={{
                  width: "100%",
                  height: "auto",
                  marginBottom: "-40px",
                  borderRadius: "19px",
                }}
              />
              <Box className={imageGradientCss} />
            </Card>
          </Box>
        ))}
      </Box>
    </Row>
  )
}

export default FeatureCallouts
